import {
  Sheet,
  SheetOverlay,
  SheetContent,
  SheetCloseButton,
} from '@wartek-id/sheet'
import { Text } from '@wartek-id/text'
import { Button } from '@wartek-id/button-v1'
import { Separator } from '@wartek-id/separator'
import type { DialogContextType } from '@wartek-id/dialog'

export interface EmailErrorProps {
  relogin?: Function
  faqUrl: string
  accountActivationUrl?: string
}

const EmailError = ({
  relogin,
  faqUrl,
  accountActivationUrl,
}: EmailErrorProps) => (
  <div className="mt-[52px] mb-2">
    <SheetCloseButton />
    <Text variant="display-sm">Akun Tidak Terdaftar</Text>
    <Text className="mt-3">Berikut yang bisa Anda lakukan:</Text>
    <div className="py-4 flex">
      <div className="w-16 flex justify-center">
        <div className="border border-gray-30 rounded-full w-8 h-8 flex justify-center items-center">
          1
        </div>
      </div>
      <div className="flex-1">
        <div>
          Pastikan Anda menggunakan akun Google dengan akhiran belajar.id atau
          madrasah.kemenag.go.id.
        </div>
        <div>
          <a
            className="text-primary font-semibold cursor-pointer"
            onClick={() => relogin()}
          >
            Coba masuk lagi
          </a>
        </div>
      </div>
    </div>
    <Separator />
    <div className="py-4 flex">
      <div className="w-16 flex justify-center">
        <div className="border border-gray-30 rounded-full w-8 h-8 flex justify-center items-center">
          2
        </div>
      </div>
      <div className="flex-1">
        <div>Pastikan Akun belajar.id sudah aktif</div>
        <div>
          <a
            className="text-primary font-semibold cursor-pointer"
            href={accountActivationUrl}
            target="_blank"
            rel="noreferrer"
          >
            Cek dan aktifkan Akun belajar.id
          </a>
        </div>
      </div>
    </div>
    <Separator />
    <div className="py-4 flex">
      <div className="w-16 flex justify-center">
        <div className="border border-gray-30 rounded-full w-8 h-8 flex justify-center items-center">
          3
        </div>
      </div>
      <div className="flex-1">
        <div>Jika Anda tetep belum bisa masuk, mohon hubungi bantuan.</div>
        <div>
          <a
            className="text-primary font-semibold cursor-pointer"
            href={faqUrl}
          >
            Hubungi Bantuan
          </a>
        </div>
      </div>
    </div>
  </div>
)

const WhitelistError = ({ faqUrl }: EmailErrorProps) => (
  <div className="mt-[52px] mb-2">
    <SheetCloseButton />
    <Text variant="display-sm">Akun Belum Terdaftar</Text>
    <Text className="mt-3">Hal ini mungkin terjadi karena:</Text>
    <div className="py-4 flex">
      <div className="w-16 flex justify-center">
        <div className="border border-gray-30 rounded-full w-8 h-8 flex justify-center items-center">
          1
        </div>
      </div>
      <div className="flex-1">
        <div>
          Sekolah Anda tidak terdaftar di program Sekolah Penggerak atau SMK
          Program Keunggulan. Nantikan akses untuk semua guru, segera!
        </div>
      </div>
    </div>
    <Separator />
    <div className="py-4 flex">
      <div className="w-16 flex justify-center">
        <div className="border border-gray-30 rounded-full w-8 h-8 flex justify-center items-center">
          2
        </div>
      </div>
      <div className="flex-1">
        <div>
          Terjadi kendala login. Jika sekolah Anda sudah terdaftar di program di
          atas dan Anda sudah menggunakan Akun belajar.id tapi tidak bisa masuk,
          silakan Hubungi Bantuan.{' '}
          <a
            className="text-primary font-semibold cursor-pointer"
            href={faqUrl}
          >
            Hubungi Bantuan
          </a>
        </div>
      </div>
    </div>
  </div>
)

interface OtherErrorProps {
  relogin: Function
}

const OtherError = ({ relogin }: OtherErrorProps) => (
  <div className="py-6">
    <Text variant="display-sm">Terjadi Kendala Sistem</Text>
    <Text className="mt-3">Silakan coba lagi dalam beberapa saat.</Text>
    <Button fullWidth className="mt-12" onClick={() => relogin()}>
      Coba Lagi
    </Button>
  </div>
)

export type ErrorType = 'email' | 'whitelist' | 'other'

export interface ErrorLoginSheetProps
  extends DialogContextType,
    EmailErrorProps {
  errorType: ErrorType
}
const ErrorLoginSheet = ({
  isOpen,
  onClose,
  errorType,
  relogin,
  faqUrl,
  accountActivationUrl,
}: ErrorLoginSheetProps) => {
  return (
    <Sheet isOpen={isOpen} onClose={onClose}>
      <SheetOverlay as="span" />
      <SheetContent className="max-w-xl m-auto px-4">
        {errorType === 'email' && (
          <EmailError
            relogin={relogin}
            faqUrl={faqUrl}
            accountActivationUrl={accountActivationUrl}
          />
        )}
        {errorType === 'whitelist' && <WhitelistError faqUrl={faqUrl} />}
        {errorType === 'other' && <OtherError relogin={relogin} />}
      </SheetContent>
    </Sheet>
  )
}

export default ErrorLoginSheet
