import React from 'react'
import Image from 'next/image'
import { useRouter } from 'next/router'

import { Button } from '@wartek-id/button-v1'
import { Text } from '@wartek-id/text'
import { useToast } from '@wartek-id/toast'

import { useAuth } from 'utils/auth'
import { parseQuery } from 'utils/router'
import ErrorLoginSheet from 'app/LoginPage/ErrorLoginSheet'
import { useGATrackEvent } from 'app/LoginPage/hooks/useGATrackEvent'
import { TRACKER_PAGE_ELEMENTS } from 'app/LoginPage/constants'
import AndroidHandler from 'utils/androidHandler'
import { GATrackEvent } from 'utils/googleAnalytics'
import { getRouterBasePath } from 'utils/router'
import { useTogglesStore } from 'stores/toggles'
import { isToggleEnabled } from '../configs/toggle-feature'

import type { ErrorType } from 'app/LoginPage/ErrorLoginSheet'

let customError: string | null = null

// Page FAQ Login staging not found
const FAQLoginUrl = `${process.env.NEXT_PUBLIC_PUSAT_INFORMASI_BASE_URL}/hc/en-us/articles/6091880695577-Masuk-Login-ke-Platform-Merdeka-Mengajar`
const AccountActivationURL = 'https://belajar.id/'
const PrivaryPolicyURL = '/policy'

export async function getServerSideProps() {
  const isRuangGtkEnabled = await isToggleEnabled('ruang-gtk-enabled')

  return {
    props: {
      isRuangGtkEnabled,
    },
  }
}

const LoginPage = ({ isRuangGtkEnabled = false }) => {
  const router = useRouter()
  const { query } = router
  const toast = useToast()
  const androidHandler = AndroidHandler()
  const {
    session,
    login,
    loginScriptIsLoaded,
    validateTokenError,
    lastUsedEmailDomain,
    error: googleAuthError,
  } = useAuth()
  const [showErrorLoginSheet, setShowErrorLoginSheet] = React.useState(false)
  const [errorLoginSheetType, setErrorLoginSheetType] =
    React.useState<ErrorType>('other')
  const { GATrackEventLoginPage } = useGATrackEvent()

  // Toggle for Pengelolaan Kinerja Landing Page
  const isPengelolaanKinerjaLandingPageEnabled = useTogglesStore(
    (state) => state.toggles?.['kinerja-2025-landing-page-enabled']
  )

  const queryFrom = React.useMemo(() => {
    const parsedQueryFrom = parseQuery<string>(query?.from)
    return parsedQueryFrom === '/logout' ? '/home' : parsedQueryFrom
  }, [query?.from])

  // Resolve Pengelolaan Kinerja version of the login page
  const isPengelolaanKinerja = React.useMemo(() => {
    const referer = parseQuery(query?.referer, 'string')
    const isRefereredByPengelolaanKinerja = referer === 'pengelolaan-kinerja'

    const from = parseQuery(query?.from, 'string')
    const isFromPengelolaanKinerja = from?.includes('/pengelolaan-kinerja')

    return (
      isPengelolaanKinerjaLandingPageEnabled &&
      (isRefereredByPengelolaanKinerja || isFromPengelolaanKinerja)
    )
  }, [query, isPengelolaanKinerjaLandingPageEnabled])

  const relogin = () => {
    setShowErrorLoginSheet(false)
    login()
  }

  // Log if user is from webview but still accessing login page
  React.useEffect(() => {
    if (androidHandler.enabled) {
      GATrackEvent({
        eventName: 'EMBEDDED_WEBVIEW_LOGIN',
        sendDefaultParams: true,
        eventParams: {},
      })
    }
  }, [androidHandler.enabled])

  React.useEffect(() => {
    if ([401, 403].includes(validateTokenError?.response?.status)) {
      setErrorLoginSheetType(
        lastUsedEmailDomain?.includes('belajar.id') ? 'whitelist' : 'email'
      )
      setShowErrorLoginSheet(true)
    } else if (googleAuthError && googleAuthError !== 'popup_closed_by_user') {
      setErrorLoginSheetType('other')
      setShowErrorLoginSheet(true)
    }
  }, [googleAuthError, lastUsedEmailDomain, session, validateTokenError])

  React.useEffect(() => {
    if (customError) {
      return
    }

    if (queryFrom) {
      GATrackEventLoginPage({
        activityType: 'LOGIN_PAGE',
        pageElement:
          TRACKER_PAGE_ELEMENTS?.[getRouterBasePath(queryFrom)] ?? null,
      })
    }

    if (query?.error) {
      customError = query.error as string
    }

    customError === 'SessionError' &&
      toast({
        message:
          'Sesi berakhir sehingga Anda keluar otomatis dari akun. Silakan masuk lagi.',
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  if (session) {
    const RGX_LEADING_SLASH = /^\//
    const isRelativePath = RGX_LEADING_SLASH.test(queryFrom)

    if (isRelativePath) {
      window.location.replace(`${window.location.origin}${queryFrom}`)
    } else {
      router.replace('/home')
    }
    return null
  }

  const isReferredBySimpkb = () => {
    const targetString = '?referer=simpkb'
    const { from } = router?.query || { from: null }
    if (!from) return false

    if (Array.isArray(from))
      return from.some((fr) => fr?.toLowerCase()?.includes(targetString))
    return from?.toLowerCase()?.includes(targetString)
  }

  return (
    <>
      <div className="min-h-full h-full bg-white flex flex-col">
        <div className="flex flex-grow items-center">
          <div className="flex-1 px-4 mt-8 md:mt-0 mb-4">
            {!isPengelolaanKinerja && (
              <Image
                className="mt-3"
                priority
                src={
                  isRuangGtkEnabled
                    ? '/images/ruang-gtk/logo.svg'
                    : '/images/logo2x.png'
                }
                alt={isRuangGtkEnabled ? 'Ruang GTK' : 'Merdeka Mengajar'}
                width={isRuangGtkEnabled ? 130 : 105}
                height={isRuangGtkEnabled ? 72 : 40}
              />
            )}

            <div className="mt-3 font-semibold">
              {isRuangGtkEnabled ? (
                <Text variant="heading-lg">
                  {isReferredBySimpkb()
                    ? 'Masuk dan Ikuti Uji Kompetensi Platform Ruang GTK'
                    : isPengelolaanKinerja
                    ? 'Masuk ke Pengelolaan Kinerja'
                    : 'Masuk dan akses beragam  inspirasi bagi guru, kepala sekolah, pengawas sekolah, dan tenaga kependidikan.'}
                </Text>
              ) : (
                <Text variant="heading-lg">
                  {isReferredBySimpkb()
                    ? 'Masuk dan Ikuti Uji Kompetensi Platform Merdeka Mengajar'
                    : isPengelolaanKinerja
                    ? 'Masuk ke Pengelolaan Kinerja'
                    : 'Masuk dan akses beragam referensi untuk membantu pendidik yang merdeka.'}
                </Text>
              )}
            </div>

            <div className="mt-3">
              <Text color="subdued" variant="eyebrow-lg">
                Gunakan email berakhiran <b>belajar.id</b> atau{' '}
                <b>madrasah.kemenag.go.id</b>.
              </Text>
            </div>

            <div className={isPengelolaanKinerja ? 'mt-20' : 'mt-6'}>
              <Button
                data-testid="login-button"
                fullWidth
                className="flex !p-1 rounded-lg mb-4"
                onClick={login}
                disabled={!loginScriptIsLoaded}
              >
                <div className="w-[38px] h-[38px] rounded-sm bg-white flex justify-center items-center">
                  <Image
                    src="/images/google-logo.png"
                    width={20}
                    height={20}
                    alt="Google logo"
                  />
                </div>
                <div className="flex-1 font-semibold text-[15px] leading-[22px]">
                  Masuk
                </div>
              </Button>
            </div>

            <div className="my-6">
              <Text variant="body-sm" color="subdued">
                Tidak tahu alamat email belajar.id (Akun Pembelajaran) Anda ?{' '}
                <span className="!text-interactive-default !font-semibold">
                  <a
                    href={AccountActivationURL}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Temukan di sini.
                  </a>
                </span>
              </Text>
            </div>
          </div>
        </div>

        <div className="mb-6 px-4">
          <Text variant="helper" color="subdued">
            Dengan Masuk ke akun, berarti Anda telah memahami dan menyetujui{' '}
            <a
              className="!text-interactive-default !font-semibold"
              href={PrivaryPolicyURL}
              target="_blank"
              rel="noreferrer"
            >
              Kebijakan Privasi
            </a>{' '}
            yang berlaku.
          </Text>
        </div>
      </div>
      <ErrorLoginSheet
        isOpen={showErrorLoginSheet}
        onClose={setShowErrorLoginSheet}
        relogin={relogin}
        errorType={errorLoginSheetType}
        faqUrl={FAQLoginUrl}
        accountActivationUrl={AccountActivationURL}
      />
    </>
  )
}

export default LoginPage
