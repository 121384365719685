import { GATrackEvent } from 'utils/googleAnalytics'
import { useAuth } from 'utils/auth'

type TGATrackEventLoginPageProps = {
  activityType: string
  pageElement: string
  eventTrigger?: string
  screenName?: string
}

export const useGATrackEvent = () => {
  const { session } = useAuth()

  const GATrackEventLoginPage = ({
    activityType = '',
    pageElement = '',
    eventTrigger = 'HOMEPAGE',
    screenName = 'LOGIN_PAGE',
  }: TGATrackEventLoginPageProps) => {
    GATrackEvent({
      eventName: 'GURU_CORE_APP',
      sendDefaultParams: true,
      eventParams: {
        event_trigger: eventTrigger,
        referrer_screen_name: eventTrigger,
        screen_name: screenName,
        activity_type: activityType,
        is_login: !!session,
        page_location: window.location.origin,
        page_element: pageElement,
      },
    })
  }

  return { GATrackEventLoginPage }
}
